import React from 'react';
import { Link } from 'react-router-dom';
import Services from '../../api/service';
import Logo from '../../images/logo.png';

const Footer = (props) => {
  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src={Logo} alt="blog" />
                </div>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <p>
                Trust us to bring cleanliness and order to your space, so you
                can focus on what truly matters.
              </p>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Quick Link</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/about">About Us </Link>
                  </li>
                  <li>
                    <Link to="/service">Service</Link>
                  </li>
                  <li>
                    <a href="/#appointment">Appointment</a>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us </Link>
                  </li>

                  <li>
                    <Link to="/terms">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Services</h3>
                </div>
                <ul>
                  {Services.slice(0, 5).map((service, srv) => (
                    <li key={srv} className="cut-text-1">
                      {service.sTitle}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12 mt-2">
              <p className="copyright">
                {' '}
                Copyright &copy; {new Date().getFullYear()} | All Rights
                Reserved
              </p>
            </div>
            <div className="col-lg-6 col-12 mt-2">
              <p className="copyright">
                Designed & Developed by{' '}
                <a
                  href="https://www.zartek.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Zartek
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
