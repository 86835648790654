import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import Services from '../../api/service';
import cimg from '../../images/appointment.jpeg';

const Appointment = () => {
  const [forms, setForms] = useState({
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const [validator] = useState(
    new SimpleReactValidator({
      className: 'errorMessage',
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();

      setLoading(true);

      await axios.post(process.env.REACT_APP_BASE_URL + '/appointment', {
        name: forms.name,
        email: forms.email,
        contact_number: forms.phone,
        service: forms.subject,
        message: forms.message,
      });

      toast('Request sent successfully, we will get back to you soon!', {
        type: 'success',
      });
      setLoading(false);

      setForms({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <section className="wpo-contact-section section-padding" id="appointment">
      <div className="container">
        <div className="wpo-contact-section-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-contact-form-area">
                <div className="wpo-section-title-s2">
                  <h2>Make An Appointment</h2>
                </div>
                <form
                  onSubmit={(e) => submitHandler(e)}
                  className="contact-validation-active"
                >
                  <div className="row">
                    <div className="col col-lg-6 col-12">
                      <div className="form-field">
                        <input
                          className="form-control"
                          value={forms.name}
                          type="text"
                          name="name"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Your Name"
                        />
                      </div>
                      {validator.message(
                        'name',
                        forms.name,
                        'required|alpha_space'
                      )}
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-field">
                        <input
                          className="form-control"
                          value={forms.email}
                          type="email"
                          name="email"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Your Email"
                        />
                        {validator.message(
                          'email',
                          forms.email,
                          'required|email'
                        )}
                      </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-field">
                        <input
                          className="form-control"
                          value={forms.phone}
                          type="phone"
                          name="phone"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Your phone"
                        />
                        {validator.message('phone', forms.phone, 'required')}
                      </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-field">
                        <select
                          className="form-control"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          value={forms.subject}
                          type="text"
                          name="subject"
                        >
                          <option value="">Choose a Service</option>
                          {Services.map(({ Id, sTitle }, idx) => {
                            return (
                              <option value={sTitle} key={idx}>
                                {sTitle}
                              </option>
                            );
                          })}
                        </select>
                        {validator.message(
                          'subject',
                          forms.subject,
                          'required'
                        )}
                      </div>
                    </div>
                    <div className="col fullwidth col-lg-12 ">
                      <textarea
                        className="form-control"
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Message"
                      ></textarea>
                      {validator.message('message', forms.message, 'required')}
                    </div>
                  </div>
                  <div className="submit-area">
                    <button
                      type="submit"
                      className="theme-btn"
                      disabled={loading}
                    >
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>{' '}
                      {loading ? 'Please wait' : 'Submit Now'}
                    </button>
                  </div>
                </form>
                <div className="border-style"></div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="wpo-contact-img">
                <img src={cimg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Appointment;
