import t11 from '../images/our-clients/11.jpg';
import t12 from '../images/our-clients/12.jpg';
import t13 from '../images/our-clients/13.jpg';
import t14 from '../images/our-clients/14.jpg';
import t18 from '../images/our-clients/15.jpeg';
import t16 from '../images/our-clients/16.jpeg';
import t17 from '../images/our-clients/17.jpeg';
import img1 from '../images/our-clients/326523676_1680211019101196_3286133549734355548_n.png';
import t15 from '../images/our-clients/D9D269C6-E10E-406D-80E8-540E19E4C3B9.jpg';
import img2 from '../images/our-clients/Grand-Hyatt.png';
import img10 from '../images/our-clients/WhatsApp Image 2023-06-21 at 15.25.09.jpg';
import img4 from '../images/our-clients/img_1_1685960901030.jpg';
import img5 from '../images/our-clients/img_1_1685961477444.jpg';
import img6 from '../images/our-clients/img_1_1685961600020.jpg';
import img7 from '../images/our-clients/img_1_1685965116791.jpg';
import img8 from '../images/our-clients/img_1_1685965544922.jpg';
import img9 from '../images/our-clients/img_3_1685965575298.jpg';

const Projects = [
  {
    Id: '1',
    pImg: img1,
  },
  {
    Id: '2',
    pImg: img2,
  },
  {
    Id: '4',
    pImg: img4,
  },
  {
    Id: '5',
    pImg: img5,
  },
  {
    Id: '6',
    pImg: img6,
  },
  {
    Id: '7',
    pImg: img7,
  },
  {
    Id: '8',
    pImg: img8,
  },
  {
    Id: '9',
    pImg: img9,
  },
  {
    Id: '10',
    pImg: img10,
  },
  {
    Id: 't11',
    pImg: t11,
  },
  {
    Id: 't12',
    pImg: t12,
  },
  {
    Id: 't13',
    pImg: t13,
  },
  {
    Id: 't14',
    pImg: t14,
  },
  {
    Id: 't15',
    pImg: t15,
  },
  {
    Id: 't16',
    pImg: t16,
  },
  {
    Id: 't17',
    pImg: t17,
  },
  {
    Id: 't18',
    pImg: t18,
  },
];
export default Projects;
