/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import womenIco from '../../images/girl.png';
import manIco from '../../images/man.png';

const PricingSection = () => {
  return (
    // <div className="container mb-5">
    //   {/*this is my pricing main container*/}
    //   <main className="pricing-tex text-center">
    //     <div className="d-flex justify-content-center" />
    //     <div className="pricing">
    //       <h1>Pricing</h1>
    //       <p className="pricing-text p-4 pt-0">
    //         Lorem ipsum dolor sit amet consectetur, adipisicing elit. A ipsum
    //         reiciendis odio magnam vitae vel atque tenetur reprehenderit impedit
    //         laudantium excepturi est pariatur nihil, inventore eveniet voluptate
    //         ducimus dolor similique!
    //       </p>
    //     </div>
    //     {/*this is my cards*/}
    //     <div className="cards row-cols-sm-1 row-cols-md-3 d-md-flex justify-content-around">
    //       <div className="card m-auto my-2" style={{ width: '18rem' }}>
    //         <div className="card-header fs-3">Male staff</div>
    //         <div className="card-body">
    //           <h5 className="card-title fs-2">
    //             25qr
    //             <span className="fw-light">/Hr</span>
    //           </h5>
    //         </div>
    //       </div>
    //       <div className="card m-auto my-2" style={{ width: '18rem' }}>
    //         <div className="card-header fs-3">Female staff</div>
    //         <div className="card-body">
    //           <h5 className="card-title fs-2">
    //             30qr<span className="fw-light">/Hr</span>
    //           </h5>
    //         </div>
    //       </div>
    //     </div>
    //     <p className="mt-3">
    //       <strong>Note : </strong>
    //       These prices do not include cleaning material costs (10qr per hour)
    //     </p>
    //   </main>
    // </div>

    <section className="wpo-pricing-section mb-5">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6">
            <div className="wpo-section-title mb-5">
              <h2>Pricing</h2>
            </div>
          </div>
        </div>
        <div className="pricing-grids  row align-items-center justify-content-center pt-1 clearfix">
          <div className="grid col-6">
            <div className="pt-4">
              <img src={manIco} alt="..." width={64} height={64} />
            </div>
            <div className="pricing-header">
              <div>
                <h3 className="price">25QR</h3>
                <p>Per Hour</p>
              </div>
            </div>
            <div className="pricing-body">
              {/* <ul>
                <li>Surfaces Hand Wiped</li>
                <li>Bedrooms cleaning</li>
                <li>Bedrooms cleaning</li>
                <li>Window cleaning</li>
                <li>Floor cleaning</li>
              </ul> */}
              <a href="/#appointment" className="get-started">
                Book now
              </a>
            </div>
          </div>

          <div className="grid col-6">
            <div className="pt-4">
              <img src={womenIco} alt="..." width={64} height={64} />
            </div>
            <div className="pricing-header">
              <div>
                <h3 className="price">30QR</h3>
                <p>Per Hour</p>
              </div>
            </div>
            <div className="pricing-body">
              {/* <ul>
                <li>Surfaces Hand Wiped</li>
                <li>Bedrooms cleaning</li>
                <li>Bedrooms cleaning</li>
                <li>Window cleaning</li>
                <li>Floor cleaning</li>
              </ul> */}
              <a href="/#appointment" className="get-started">
                Book now
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <p className="mt-5 text-center">
          <strong>Note : </strong>
          These prices do not include cleaning material costs (10QR per hour){' '}
        </p>
      </div>
    </section>
  );
};

export default PricingSection;
