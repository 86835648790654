import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import AboutPage from '../AboutPage/AboutPage';
import BlogDetailsFull from '../BlogDetailsFull/BlogDetailsFull';
import BlogPageFullwidth from '../BlogPageFullwidth/BlogPageFullwidth';
import ContactPage from '../ContactPage/ContactPage';
import ErrorPage from '../ErrorPage/ErrorPage.js';
import Homepage2 from '../HomePage2/HomePage2';
import ServicePageS2 from '../ServicePageS2/ServicePageS2';
import TermPage from '../TermPage/TermPage';

const AllRoute = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Homepage2} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/service" component={ServicePageS2} />

          <Route path="/terms" component={TermPage} />

          <Route path="/blog/:id" exact component={BlogDetailsFull} />
          <Route path="/blog" component={BlogPageFullwidth} />

          <Route path="*" component={ErrorPage} />
        </Switch>
      </Router>
    </div>
  );
};

export default AllRoute;
