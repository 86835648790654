import React from 'react';
import Services from '../../api/service';

const ServiceSectionS2 = (props) => {
  return (
    <section className="wpo-service-section-s2 section-padding">
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="wpo-section-title-s2">
            <h2>What We Do?</h2>
          </div>
        </div>
        <div className="wpo-service-wrap wpo-service-slide">
          <div className="row">
            {Services.map((service, srv) => (
              <div className="col-lg-4 col-md-6 col-12 mb-4" key={srv}>
                <div className="wpo-service-item h-100">
                  <div className="wpo-service-icon">
                    <div className="icon">
                      <img src={service.sIcon} alt="" />
                    </div>
                  </div>
                  <div className="wpo-service-text">
                    <h2>{service.sTitle}</h2>
                  </div>
                  <ul>
                    <li>{service.description}</li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSectionS2;
