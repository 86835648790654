import { default as sIcon10, default as sIcon12 } from '../images/icon/5.svg';
import emergency from '../images/icon/broom.png';
import carpet from '../images/icon/carpet.png';
import construction from '../images/icon/construction.png';
import deep from '../images/icon/deep.png';
import moveInOut from '../images/icon/express-delivery.svg';
import hospitality from '../images/icon/hospitality.png';
import janitor from '../images/icon/janitor.png';
import office from '../images/icon/office.png';
import pool from '../images/icon/pool.png';
import regular from '../images/icon/regular-clean.png';
import special from '../images/icon/tools.png';
import Majlis from '../images/icon/vacuum-cleaner.png';
import window from '../images/icon/window.png';

const Services = [
  {
    Id: '1',
    sIcon: regular,
    sTitle: 'Regular cleaning',
    description:
      'Routine tasks like dusting, vacuuming, mopping, and bathroom cleaning to maintain cleanliness and hygiene in a space.',
  },
  {
    Id: '2',
    sIcon: deep,
    sTitle: 'Deep cleaning',
    description:
      'Thoroughly clean hard-to-reach areas, high-touch surfaces, and perform detailed tasks like carpet and window cleaning for a comprehensive clean.',
  },
  {
    Id: '3',
    sIcon: moveInOut,
    sTitle: 'Move-in/move-out cleaning',
    description:
      'Deep cleaning services for individuals shifting homes/offices, including thorough cleaning of all areas and appliances to leave the space in pristine condition.',
  },
  {
    Id: '4',
    sIcon: construction,
    sTitle: 'Post-construction cleaning',
    description:
      'Remove debris, dust, and residue after construction/renovation, including cleaning walls, floors, windows, and sanitizing restrooms for a clean and safe environment.',
  },
  {
    Id: '5',
    sIcon: office,
    sTitle: 'Office and commercial cleaning',
    description:
      'Cleaning and maintenance services for professional environments, including workstations, floors, restrooms, and common areas to ensure a clean and organized space.',
  },
  {
    Id: '6',
    sIcon: janitor,
    sTitle: 'Janitorial services',
    description:
      'Ongoing cleaning and maintenance tasks such as restroom cleaning, trash removal, floor care, and special event support to keep the facility clean and functional.',
  },
  {
    Id: '7',
    sIcon: carpet,
    sTitle: 'Carpet and upholstery cleaning',
    description:
      'Specialized services for cleaning and maintaining carpets, rugs, and furniture upholstery, including steam cleaning, stain removal, and odor elimination for a refreshed and revitalized look.',
  },
  {
    Id: '8',
    sIcon: window,
    sTitle: 'Window cleaning',
    description:
      'Window cleaning services include interior and exterior cleaning, frame and track cleaning, sticker and residue removal, hard water stain removal, and high-rise window cleaning (for specialized companies).',
  },
  {
    Id: '9',
    sIcon: sIcon10,
    sTitle: 'Power washing',
    description:
      'Power washing services involve pressure washing of exterior surfaces, including sidewalks, driveways, parking lots, decks, patios, and exterior walls, as well as graffiti removal.',
  },
  {
    Id: '10',
    sIcon: special,
    sTitle: 'Specialized cleaning services',
    description:
      'Specialized cleaning services cater to industries such as healthcare facilities, restaurants, kitchens, gyms, educational institutions, industrial facilities, laboratories, cleanrooms, hotels, and auto dealerships, providing tailored cleaning and sanitization solutions.',
  },
  {
    Id: '11',
    sIcon: emergency,
    sTitle: 'Emergency cleaning',
    description:
      'Emergency cleaning services address urgent situations such as water damage restoration, fire and smoke damage cleanup, mold remediation, and biohazard cleanup.',
  },
  {
    Id: '12',
    sIcon: pool,
    sTitle: 'Pool Cleaning',
    description:
      'Pool cleaning services encompass skimming, surface cleaning, vacuuming, brushing, filtration system maintenance, chemical treatment, algae treatment, pool cover maintenance, and equipment and chemical supply.',
  },
  {
    Id: '13',
    sIcon: Majlis,
    sTitle: 'Majlis cleaning',
    description:
      'Majlis cleaning services include dusting, vacuuming, upholstery and carpet cleaning, floor cleaning, glass and mirror cleaning, and air freshening for traditional Arab gathering spaces.',
  },
  {
    Id: '14',
    sIcon: hospitality,
    sTitle: 'Hospitality Services',
    description:
      'Hospitality services enhance the guest experience by offering a range of support staff, including stewarding personnel, laundry assistants, tea service attendants, and dedicated housekeeping staff. These professionals ensure a seamless and enjoyable stay for guests, providing attentive service and maintaining the cleanliness and comfort of the premises.',
  },
  {
    Id: '15',
    sIcon: sIcon12,
    sTitle: 'Bundle Services',
    description:
      'We offer bundle services that are tailored to the unique requirements of our clients. Our goal is to deliver customized solutions that not only meet their expectations but also offer cost-effective options.',
  },
];
export default Services;
