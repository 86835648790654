import timg3 from '../images/our-team/Anwar Sadath - Project Manager.jpg';
import timg5 from '../images/our-team/Mohamed Nazar.jpeg';
import timg1 from '../images/our-team/Operations manager - Sasidharan V.P.jpg';
import timg2 from '../images/our-team/SANANTH RAJ - Sales Manager.jpeg.jpg';
import ceo from '../images/our-team/Saleem Kainikkara - CEO.jpg';
import timg4 from '../images/our-team/accountant -Nidhin K.B.JPG';

const Team = [
  {
    Id: '0',
    tImg: ceo,
    name: 'Saleem Kainikkara',
    title: 'Chief executive officer',
  },
  {
    Id: '1',
    tImg: timg1,
    name: 'Sasidharan V P',
    title: 'Operations manager',
  },
  {
    Id: '2',
    tImg: timg2,
    name: 'SANANTH RAJ',
    title: 'Sales Manager',
  },
  {
    Id: '3',
    tImg: timg3,
    name: 'Anwar Sadath',
    title: 'Project Manager',
  },
  {
    Id: '4',
    tImg: timg4,
    name: 'Nidhin K B',
    title: 'Finance manager',
  },
  {
    Id: '5',
    tImg: timg5,
    name: 'Mohamed Nazar',
    title: 'Accountant',
  },
];

export default Team;
