import React from 'react';

const About = (props) => {
  return (
    <section className="wpo-about-section section-padding">
      <div className="container">
        <div className="wpo-about-section-wrapper">
          <div className="row align-items-center">
            {/* <div className="col-lg-5 col-md-12 col-12">
              <div className="wpo-about-img">
                <img src={abimg} alt="" />
              </div>
            </div> */}
            <div className="col-lg-12 col-md-12 col-12">
              <div className="wpo-about-content">
                <div className="wpo-section-title-s2">
                  <h2>Over 15+ Years Experience In Cleaning Service</h2>
                </div>
                <div className="wpo-about-content-inner">
                  <p>
                    Welcome to Golden Cleaning Company, Qatar's premier provider
                    of reliable and high-quality cleaning services for the past
                    15 years. We take pride in our unwavering commitment to
                    three key qualities that have been the cornerstone of our
                    success. Reliability is at the core of our operations. With
                    a track record of consistent service excellence, we have
                    earned the trust of our clients through punctuality,
                    consistency, and a steadfast dedication to meeting their
                    cleaning needs. Our reputation as a reliable partner has
                    fostered long-term relationships, serving as a testament to
                    our commitment to delivering on our promises. Quality of
                    Service is the hallmark of our approach. We understand that
                    a clean and pristine environment is essential for our
                    clients' well-being and success. Through a meticulous
                    selection process, we have assembled a team of highly
                    trained professionals who employ industry-leading techniques
                    and utilize premium cleaning products. Our attention to
                    detail and unwavering commitment to surpassing customer
                    expectations ensure that every cleaning task is executed
                    with utmost precision and care. Adaptability is ingrained in
                    our company culture. As a cleaning company operating in
                    Qatar's multicultural market, we recognize the importance of
                    tailoring our services to meet diverse needs. Whether it is
                    commercial, residential, healthcare, hospitality, or other
                    sectors, we possess the versatility and flexibility to
                    customize our cleaning solutions accordingly. Our ability to
                    adjust schedules, accommodate unique requests, and respond
                    promptly to evolving client preferences enables us to
                    consistently deliver a tailored experience that exceeds
                    expectations. At Golden Cleaning Company, we continuously
                    strive for excellence and innovation. By embracing
                    continuous improvement, investing in ongoing staff training,
                    and staying informed about emerging industry trends, we
                    remain at the forefront of the cleaning industry. We pride
                    ourselves on our ability to adapt to changing market
                    demands, ensuring that our clients receive the highest
                    standard of service and stay ahead of the curve. Choose
                    Golden Cleaning Company as your trusted cleaning partner,
                    and experience the reliability, quality, and adaptability
                    that have made us a leader in Qatar's cleaning industry. Let
                    us create a spotless and inviting environment for you, so
                    you can focus on what matters most – your success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
