// images
import blogImg1 from '../images/blog/1.jpeg';
import blogImg2 from '../images/blog/2.jpeg';
import blogImg3 from '../images/blog/3.jpeg';

import blogSingleImg1 from '../images/blog/1.jpeg';
import blogSingleImg2 from '../images/blog/2.jpeg';
import blogSingleImg3 from '../images/blog/3.jpeg';

const blogs = [
  {
    id: '1',
    url: '/blog/cleanliness-and-mental-health',
    title: 'Cleanliness and Mental Health',
    screens: blogImg1,
    description: 'The Connection between an Organized Space and Well-being',
    blogSingleImg: blogSingleImg1,
    comment: '5,975',
    blClass: 'format-standard-image',
  },
  {
    id: '2',
    title: 'Dust Control in Qatar',
    url: '/blog/dust-control-in-qatar',
    screens: blogImg2,
    description: 'Tackling the Challenge of Desert Dust',
    blogSingleImg: blogSingleImg2,
    comment: '7,275',
    blClass: 'format-standard-image',
  },
  {
    id: '3',
    title: 'Sustainable Cleaning Practices',
    url: '/blog/sustainable-cleaning-practices',
    screens: blogImg3,
    description: "Preserving Qatar's Environment for Future Generations",
    blogSingleImg: blogSingleImg3,
    comment: '6,725',
    blClass: 'format-standard-image',
  },
];
export default blogs;
