import React from 'react';
import { useParams } from 'react-router-dom';
import blogs from '../../api/blogs';
import BlogOne from './BlogOne';
import BlogThree from './BlogThree';
import BlogTwo from './BlogTwo';

const BlogSingle = (props) => {
  const { id } = useParams();

  const BlogDetails = blogs.find((item) => item.url === '/blog/' + id);

  return (
    <section className="wpo-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              <div className="post format-standard-image">
                <div className="entry-media">
                  <img src={BlogDetails.blogSingleImg} alt="" />
                </div>
                <h2 className="mt-4">Introduction</h2>
                {BlogDetails.id === '1' ? <BlogOne /> : null}
                {BlogDetails.id === '2' ? <BlogTwo /> : null}
                {BlogDetails.id === '3' ? <BlogThree /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;
