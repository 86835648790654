import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import Services from '../../api/service';

const ContactForm = () => {
  const [forms, setForms] = useState({
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: 'errorMessage',
    })
  );
  const [loading, setLoading] = useState(false);

  const changeHandler = async (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();

      setLoading(true);

      await axios.post(process.env.REACT_APP_BASE_URL + '/contact', {
        name: forms.name,
        email: forms.email,
        contact_number: forms.phone,
        service: forms.subject,
        message: forms.message,
      });

      toast('Request sent successfully, we will get back to you soon!', {
        type: 'success',
      });
      setLoading(false);
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <form
      onSubmit={(e) => submitHandler(e)}
      className="contact-validation-active"
    >
      <div className="row">
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.name}
              type="text"
              name="name"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your Name"
            />
            {validator.message('name', forms.name, 'required|alpha_space')}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.email}
              type="email"
              name="email"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your Email"
            />
            {validator.message('email', forms.email, 'required|email')}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.phone}
              type="phone"
              name="phone"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your phone"
            />
            {validator.message('phone', forms.phone, 'required')}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <select
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              value={forms.subject}
              type="text"
              name="subject"
            >
              <option value="">Choose a Service</option>
              {Services.map(({ Id, sTitle }, idx) => {
                return (
                  <option value={sTitle} key={idx}>
                    {sTitle}
                  </option>
                );
              })}
            </select>
            {validator.message('subject', forms.subject, 'required')}
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <textarea
            onBlur={(e) => changeHandler(e)}
            onChange={(e) => changeHandler(e)}
            value={forms.message}
            type="text"
            name="message"
            placeholder="Message"
          ></textarea>
          {validator.message('message', forms.message, 'required')}
        </div>
      </div>
      <div className="submit-area">
        <button type="submit" className="theme-btn" disabled={loading}>
          <i className="fa fa-angle-double-right" aria-hidden="true"></i>
          {loading ? 'Please wait' : 'Submit'}
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
