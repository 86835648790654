import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import blogs from '../../api/blogs';

const settings = {
  dots: false,
  arrows: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const BlogSection = () => {
  return (
    <section className="wpo-blog-section section-padding">
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="wpo-section-title-s2">
            <h2>Latest Blogs</h2>
          </div>
        </div>
        <div className="wpo-blog-wrap wpo-blog-slide owl-carousel">
          <Slider {...settings}>
            {blogs.map((blog, bl) => (
              <div className="wpo-blog-item" key={bl}>
                <div className="wpo-blog-img">
                  <img
                    src={blog.screens}
                    alt=""
                    style={{ height: '310px', objectFit: 'cover' }}
                  />
                </div>
                <div className="wpo-blog-text my-0">
                  <h2 style={{ minHeight: '65px' }} className="mb-0">
                    <Link onClick={ClickHandler} to={blog.url}>
                      {blog.title}
                    </Link>
                  </h2>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
