import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import blogs from '../../api/blogs.js';
import BlogSingle from '../../components/BlogDetails/BlogSingle.js';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer.js';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';

const BlogDetailsFull = () => {
  const { id } = useParams();

  const BlogDetails = blogs.find((item) => item.url === '/blog/' + id);

  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={BlogDetails.title} pagesub={'Blog'} />
      <BlogSingle blLeft={'d-none'} blRight={'col-lg-10 offset-lg-1'} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogDetailsFull;
