import React, { Fragment } from 'react';
import Appointment from '../../components/Appointment/Appointment';
import BlogSection from '../../components/BlogSection/BlogSection.js';
import Navbar from '../../components/Navbar/Navbar';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import ServiceSectionS2 from '../../components/ServiceSectionS2/ServiceSectionS2';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import WorkSection from '../../components/WorkSection/WorkSection';
import Footer from '../../components/footer/Footer.js';
import Hero2 from '../../components/hero2/Hero2';
import Scrollbar from '../../components/scrollbar/scrollbar';
import PricingSection from './Pricing';

const HomePage2 = () => {
  return (
    <Fragment>
      <Navbar />
      <Hero2 />
      <ServiceSectionS2 />
      <PricingSection />
      <WorkSection />
      <Appointment />
      <ProjectSection />
      <TeamSection />
      <Testimonial />
      <BlogSection />
      <PartnerSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage2;
