import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Projects from '../../api/project';

const settings = {
  dots: false,
  arrows: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const ProjectSection = () => {
  return (
    <section className="wpo-project-section section-padding">
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-5">
            <div className="wpo-section-title">
              <h2>Our Clients</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="wpo-project-slider">
              <Slider {...settings}>
                {Projects.map((project, prj) => (
                  <div className="item" key={prj}>
                    <div className="single-work bg-white">
                      <img
                        className="img-responsive"
                        src={project.pImg}
                        alt=""
                        style={{
                          height: '320px',
                          objectFit: 'contain',
                        }}
                      />
                      {/* <div className="hover_layer">
                        <div className="info">
                          <h3 className="cut-text-1" title={project.title}>
                            {project.title}
                          </h3>
                          <p className="cut-text-1" title={project.subTitle}>
                            {project.subTitle}
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
