import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import hero1 from '../../images/banner/1.jpeg';
import hero2 from '../../images/banner/2.jpeg';

const settings = {
  dots: false,
  arrows: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  fade: true,
};

const Hero2 = () => {
  return (
    <section className="wpo-hero-slider">
      <div className="hero-container">
        <div className="hero-wrapper">
          <Slider {...settings}>
            <div className="hero-slide">
              <div
                className="slide-inner slide-bg-image"
                style={{ backgroundImage: `url(${hero1})` }}
              >
                <div className="gradient-overlay"></div>
                <div className="container">
                  <div className="slide-content">
                    <div className="slide-title">
                      <h2>Expert Cleaning Service You Can Trust.</h2>
                    </div>
                    <div className="slide-text">
                      <p>
                        Trust us to bring cleanliness and order to your space,
                        so you can focus on what truly matters.
                      </p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="slide-btns">
                      <Link to="/about" className="theme-btn">
                        <i className="fa fa-angle-double-right"></i> Explore
                        more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hero-slide">
              <div
                className="slide-inner slide-bg-image"
                style={{ backgroundImage: `url(${hero2})` }}
              >
                <div className="gradient-overlay"></div>
                <div className="container">
                  <div className="slide-content">
                    <div className="slide-title">
                      <h2>Expert Cleaning Service You Can Trust.</h2>
                    </div>
                    <div className="slide-text">
                      <p>
                        Trust us to bring cleanliness and order to your space,
                        so you can focus on what truly matters.
                      </p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="slide-btns">
                      <Link to="/about" className="theme-btn">
                        <i className="fa fa-angle-double-right"></i> Explore
                        more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Hero2;
