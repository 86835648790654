import React from 'react';
import Team from '../../api/team';

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const TeamSection = (props) => {
  return (
    <section className="wpo-team-section section-padding">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-5">
            <div className="wpo-section-title">
              <h2>Our Team</h2>
            </div>
          </div>
        </div>
        <div className="wpo-team-wrap">
          <div className="row">
            {Team.map((team, aitem) => (
              <div className="col col-lg-6 col-md-12 col-12" key={aitem}>
                <div className="wpo-team-item">
                  <div className="wpo-team-img">
                    <img src={team.tImg} alt="" />
                  </div>
                  <div className="wpo-team-text">
                    <span>{team.title}</span>
                    <h2>{team.name}</h2>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
