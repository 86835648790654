import React, { Fragment } from 'react';
import FunFact from '../../components/FunFact/FunFact';
import Navbar from '../../components/Navbar/Navbar';
import TeamSection from '../../components/TeamSection/TeamSection';
import WorkSection from '../../components/WorkSection/WorkSection';
import About from '../../components/about/about';
import Footer from '../../components/footer/Footer.js';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';

const AboutPage = () => {
  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={'About Us'} pagesub={'About'} />
      <About />
      {/* <ServiceSection /> */}
      <WorkSection />
      <TeamSection />
      {/* <Testimonial /> */}
      <FunFact />
      {/* <PartnerSection /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
