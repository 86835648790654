import React, { Fragment } from 'react';
import BlogList from '../../components/BlogList/BlogList.js';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer.js';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.svg';

const BlogPageFullwidth = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle pageTitle={'Blogs'} pagesub={'Blog'} />
      <BlogList blLeft={'d-none'} blRight={'col-lg-10 offset-lg-1'} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogPageFullwidth;
